"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateDifferenceString = exports.dateDifference = void 0;
const util_1 = require("util");
const units = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 1000 * 60,
    hours: 1000 * 60 * 60,
    days: 1000 * 60 * 60 * 24,
    weeks: 1000 * 60 * 60 * 24 * 7
};
/**
 * Returns the difference between two dates in the given `granularity` unit
 *   inclusive of the current unit (i.e. 12/01 - 12/30 will be 30 days.)
 *
 * @param from First date
 * @param to Second date.
 * @param granularity Units to return. Defaults to days.
 */
const dateDifference = (from, to, granularity = 'days') => {
    const startEpoch = util_1.isDate(from) ? from.getTime() : from;
    const endEpoch = util_1.isDate(to) ? to.getTime() : to;
    const diff = Math.ceil((endEpoch - startEpoch) / units[granularity]);
    return diff + (diff < 0 ? 0 : 1);
};
exports.dateDifference = dateDifference;
/**
 * Returns the difference between two dates in the given `granularity` unit
 *   inclusive of the current unit (i.e. 12/01 - 12/30 will be 30 days.)
 *
 * Will append the `granularity` unit to the end, and ' ago' if it's a
 *   negative number.
 *
 * @param from First date
 * @param to Second date.
 * @param granularity Units to return. Defaults to days.
 */
const dateDifferenceString = (from, to, granularity = 'days') => {
    const difference = exports.dateDifference(from, to, granularity);
    const absDifference = Math.abs(difference);
    return `${absDifference} ${absDifference === 1 ? granularity.substr(0, granularity.length - 1) : granularity}${difference < 0 ? ' ago' : ''}`;
};
exports.dateDifferenceString = dateDifferenceString;
